.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
}

.overlay iframe {
  display: block;
  max-width: 100%;
  max-height: 80%;
  margin: 20%;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.overlay img {
  display: block;
  width: 640px;
  height: 360px;
  margin: auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.overlay > span {
  position: absolute;
  top: 20px;
  right: 40px;
  font-size: 30px;
  color: #ffffff;
  z-index: 1;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .overlay img {
    width: 80%;
    height: 60%;
    margin: auto;
  }

  .overlay iframe {
    max-width: 100%;
    max-height: 50%;
    margin: auto 20px;
  }

  .overlay > span {
    top: 120px;
    right: 20px;
  }
}

@media screen and (max-width: 600px) {
  .overlay img {
    width: 60%;
    height: 40%;
    margin: auto;
  }
}
