footer {
  position: block;
  background-color: gray;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  text-align: center;
  color: white;
  font-size: 0.8em;
}
