.theme-selector {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 1200px;
  margin: 20px auto;
}

@media screen and (max-width: 768px) {
  .theme-selector {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 1200px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .mode-toggle h4 {
    font-size: 0.8em;
  }
}
.theme-buttons div {
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 15px;
  border-radius: 50%;
}
.mode-toggle {
  display: flex;
  margin-right: auto;
}
.mode-toggle img {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: auto;
  padding-right: 20px;
}
