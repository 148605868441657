.navbar {
  /* background: #58249c; */

  color: #fff;
}
.navbar nav {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}
.navbar a.brand {
  margin-right: auto;
  color: #fff;
  text-decoration: none;
}

.navbar a {
  color: #fff;
  text-decoration: none;
  margin-left: 15px;
  font-size: large;
  border-radius: 4px;
  padding: 6px;
}

.navbar img.test {
  display: none;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .navbar img.test {
    display: flex;
    cursor: pointer;
  }
  .navbar div.hideNavbar {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .navbar nav {
    width: 100%;
  }

  .navbar a {
    font-size: medium;
  }
}

.navbar a:hover {
  background: #fff;
  color: #333;
}
