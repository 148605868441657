.recipe-list-web {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  max-width: 1200px;
  margin: 40px auto;
}
.recipe-list-web .card {
  background: #fff;
  color: #333;
  padding-bottom: 10px;
  padding-top: 6px;
  border-radius: 4px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
}

/* .recipe-list .card:hover {
    transform: rotate(3deg);
  } */
.recipe-list-web .card h4 {
  color: #555;
  margin-bottom: 6px;
  margin-top: 6px;
  text-align: center;
}
.recipe-list-web .card p {
  color: #999;
  font-size: 0.9em;
}
.recipe-list-web .card div {
  color: #555;
  font-size: 0.7em;
  margin: 20px 0;
  line-height: 1.5em;
}

.recipe-list-web img {
  display: block;
  margin: auto;
  width: 450px;
}

@media screen and (max-width: 768px) {
  .recipe-list-web {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    max-width: 1200px;
    margin: 40px;
  }
  .recipe-list-web .card h4 {
    font-size: 0.8em;
  }
  .recipe-list-web .img {
    width: 400px;
  }
}

@media screen and (max-width: 600px) {
  .recipe-list-web .img {
    width: 150px;
  }
}

.recipe-list-web button {
  display: block;
  font-size: 1em;
  color: #fff;
  padding: 8px 20px;
  border: 0;
  border-radius: 4px;
  background-color: #58249c;
  cursor: pointer;
  text-decoration: none;
  margin: auto;
  font-size: medium;
}
.recipe-list-web .card a {
  color: #555;
  text-decoration: none;
  display: block;
  background: #e2e2e2;
  font-size: 0.9em;
  width: 100px;
  text-align: center;
  width: 120px;
  padding: 8px;
  border-radius: 4px;
  margin: 20px auto 0;
}
.delete {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  filter: invert(60%);
}

/* dark mode */
.recipe-list-web .card.dark {
  background: #555;
}
.recipe .dark {
  background: #555;
}

.recipe-list-web .card.dark p,
.recipe-list-web .card.dark h4,
.recipe-list-web .card.dark div {
  color: #e4e4e4;
}
