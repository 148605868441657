.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  font-size: 1.2rem;
  gap: 5px;
}

.pagination .page-num {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
}

.pagination .page-num:hover {
  background-color: #5ab1bb;
  color: #fff;
}

.pagination .active {
  padding: 8px 2px 8px 2px;
  border-radius: 3px;
  background-color: #5ab1bb;
}
