@media screen and (max-width: 768px) {
  p {
    margin-left: 30px;
  }
  h3 {
    margin-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  p {
    font-size: large;
  }
  h3 {
    font-size: large;
  }
}
