.home {
  max-width: 1200px;
  margin: 20px auto;
}

h3 {
  text-align: center;
}

.dark h3 {
  text-align: center;
}

button {
  display: block;
  font-size: 1em;
  color: #fff;
  padding: 8px 20px;
  border: 0;
  border-radius: 4px;
  background-color: #58249c;
  cursor: pointer;
  text-decoration: none;
  font-size: medium;
}
